import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import company from "../Assets/images/about-company.png";
import "../Css/Aboutus.css";
import ceovision from "../Assets/images/about-ceo-2.jpg";
import leniency from "../Assets/images/about-leniency.png";
import revolution from "../Assets/images/about-revolution.png";
import slide1 from "../Assets/images/testimonials1.png";
import signature from "../Assets/images/signature1.png";
import Footer from "./Footer";
import Goals from "./Goals";
import TestimonialCarousal from "./TestimonialCarousal";

class Aboutus extends Component {
  render() {
    return (
      <div>
        <section className="about-main ">
          {/* Company Essence */}

          <Container>
            <Row className="align-items-center about-company">
              <Col lg={7} md="auto">
                <div className="safe-content mt-lg-0 mt-4">
                  <h2 className="our-company pt-lg-5 pt-0 pb-4 pb-lg-0">
                    Our companies<span className="essence"> Essence</span>
                  </h2>
                  <Row>
                    <Col lg={5} className="px-0 d-lg-none d-block">
                      <div className="safe-reliable  pe-lg-0 p-0">
                        <img src={company} className="img-fluid " />
                      </div>
                    </Col>
                  </Row>

                  <p className="pt-3 pt-lg-0">
                    Based in Dubai, Flip Xpress has come up as a trusted
                    logistics and courier services company serving across UAE
                    and above 100 international destinations. Praised for its
                    customer service and support, Flip Xpress strives to make
                    courier services more manageable and seamless. Our skilled
                    team stands tall with its 15+ years of industry experience
                    and efficient delivery network. Established to optimise the
                    (otherwise hectic) process of parcel delivery.
                  </p>
                  <p className="">
                    Flip Xpress offers a wide plethora of services that include
                    express transportation, e-commerce courier solutions, and
                    custom logistics services to domestic and international
                    destinations. We employ road, sea and air transportation
                    channels to offer precisely tailored courier services that
                    meet your unique security compliance and care factors.
                  </p>
                </div>
              </Col>
              <Col lg={5} md="auto" className="order-first order-lg-last">
                <div>
                  <img src={company} className="img-fluid d-lg-block d-none" />
                </div>
              </Col>
            </Row>
          </Container>

          {/* CEO’s Vision */}
          <div className="bg-light">
            <Container>
              <Row className="align-items-center  company-main">
                <Col lg={5} md="auto">
                  <div className="">
                    <img
                      src={ceovision}
                      className="img-fluid d-none d-lg-block d-md-block"
                    />
                  </div>
                </Col>
                <Col lg={7} md="auto ps-1 ps-lg-0">
                  <div className="ceo-content pt-lg-5">
                    <h2 className="our-company mt-0 ceo pb-3">
                      CEO’s<span className="essence vision">Vision</span>
                    </h2>
                    <img
                      src={ceovision}
                      className="img-fluid d-lg-none d-md-none d-block"
                    />
                    <p className="pt-4 pt-lg-0">
                      C.E.O. and founder Adnan Shakeel formerly worked with
                      logistics companies like, sky com, immex and e-com company
                      like Desertcart as logistics head after exporting to 163
                      countries and over 100 million product categories had a
                      vision for connecting the whole world to and from under
                      one umbrella (Flip Xpress) he realized the difficulty he
                      faced while exporting the products and challenges faced
                      for exporting.
                    </p>
                    <p className="">
                      After exporting the vast range of products to entire world
                      he decided this is not the problem of 1 company this is
                      the problem of many companies and individuals this the
                      moment Flip Xpress was formed to flip the industry with
                      customize logistics solution.
                    </p>
                    <p className="">
                      Flip Xpress is self-formed company not funded by any
                      investor, started with the team of 3 peoples only in
                      March, 2019 and with a vision to provide solution to
                      everyone for everything to be shipped everywhere in the
                      world.
                    </p>
                    <img src={signature} className="pt-lg-4" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Leniency */}

          <div className="leniency">
            <Container className="py-5 ps-4 ps-lg-0">
              <Row className=" justify-content-center ">
                <Col md={10}>
                  <Row className="align-items-center">
                    <Col md={6} className="ps-1 ps-lg-0">
                      <div className="leniency-content pt-4 ">
                        <h3 className="leniency-head pt-lg-5 pt-0">Leniency</h3>
                        <p className="">
                          All business has different requirements, it is very
                          easy to do B2C deliveries but we believe in leniency,
                          let us know what you want and we will get it done for
                          you.
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="leniency-img">
                        <img src={leniency} className="img-fluid" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Revolution */}

          <div className="revolution">
            <Container className="py-5 ps-3 ps-lg-0">
              <Row className="justify-content-center ">
                <Col md={10}>
                  <Row className="align-items-center ps-lg-0">
                    <Col md={6}>
                      <div className="revolution-img">
                        <img src={revolution} className="img-fluid" />
                      </div>
                    </Col>
                    <Col md={6} className="order-first order-lg-last">
                      <div className="leniency-content pt-4 ">
                        <h3 className="leniency-head revolution-head pt-lg-5 pt-0">
                          Revolution
                        </h3>
                        <p className="revolution-content">
                          Successfully implementing a new idea and creating
                          value for your customers this is what we believe.
                          Revolution starts with a new idea which FXP
                          continuously following with the help of humans and
                          technology.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Testimonials */}

          <div className="bg-light testimonials-content ">
            <Container>
              <Row className="text-center">
                <Col lg={12}>
                  <div className=" py-lg-5 pb-4 pb-lg-0">
                    <h2 className="leniency-head testimonial-head">
                      Testimonials
                    </h2>
                    <p className="">What our clients have to say about us.</p>
                  </div>
                </Col>
              </Row>

              {/* <ExampleCarouselImage /> */}

              <TestimonialCarousal />
              {/* <Carousel>
                            <Carousel.Item>
                                <ExampleCarouselImage text="First slide" />

                                <Carousel.Caption>
                                  
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                            <ExampleCarouselImage text="Second slide" />
                                <Carousel.Caption>
                                    
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                            <ExampleCarouselImage text="Third slide" />
                                <Carousel.Caption>
                                    
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                            <ExampleCarouselImage text="Four slide" />
                                <Carousel.Caption>
                                    
                                </Carousel.Caption>
                            </Carousel.Item>
                            
                        </Carousel> */}
            </Container>
          </div>

          <div className="bg-white ">
            <Container className="ourgoals">
              <Row className="text-center pt-2 px-lg-5 pt-md-0">
                <Goals />
              </Row>
            </Container>
          </div>
          {/* Footer section */}

          <Footer />
        </section>
      </div>
    );
  }
}
export default Aboutus;
