import React, { Component, useState } from "react";
import eye from "../Assets/images/eye-line.png";
import "../Css/Loginform.css";
import eyeOff from "../Assets/images/eye-off-line.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Loginform() {
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const history = useHistory();
  const signup = (e) => {
    history.push(e);
  };
  const login = (e) => {
    history.push(e);
  };

  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // User Login info
  const database = [
    {
      username: "user1",
      password: "pass1",
    },
    {
      username: "user2",
      password: "pass2",
    },
  ];

  const errors = {
    uname: "invalid username",
    pass: "invalid password",
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsSubmitted(true);
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="text"
            name="uname"
            required
            placeholder="Email"
            className="p-3 my-3 bg-light  name-form login-form"
          />
          {/* {renderErrorMessage("uname")} */}
        </div>
        <div className="input-container">
          <input
            type={type}
            name="pass"
            required
            placeholder="Password"
            className="p-3  bg-light  name-form login-form"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          {renderErrorMessage("pass")}
          <span
            className="flex justify-around items-center field-icon"
            onClick={handleToggle}
          >
            <img src={icon} />
          </span>
        </div>
        <div className="forgot-password pt-3 pb-5">Forgot password?</div>
        <div className="button-container ">
          <input
            type="submit"
            value="LOGIN"
            className="login-text login-button "
            onClick={() => login("/login")}
          />
        </div>
        <div className="text-center pt-3">
          Don’t have an account?
          <span>
            <button onClick={() => signup("/signup")} className="sign-up">
              Signup
            </button>
          </span>
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        {/* <div className="title">Sign In</div> */}
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}
export default Loginform;
