import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import login from "../Assets/images/login-xp.png";
import "../Css/Login.css";
import Loginform from "./Loginform";
import Footer from "./Footer";


class Login extends Component{
  
  render(){
    return(
        <div className="login-section">
          <div className="login-sect">
         <Container >
            <Row >
                <Col lg={6} className="p-0 bg-white">
                   <div className="login-content company-main">
                  <div className="login-head">Welcome back to<span className="essence"> Flip Xpress</span></div>
                  <div className="login-para pt-3">Please login to your account</div>
                  <Loginform/>
                   </div>
                </Col>
                <Col lg={6} className="login-img p-0 d-none d-lg-block">
                    {/* <img src={login}/> */}
                    
                    <div className="login-content-img">
                    <div className="login-text-content texts-poppins-bold">“The Line Between Disorder And Order Lies In Logistics…”</div>
                    <div className="login-text-content  login-text2">-Sun Tzu</div>
                    </div>
                    
                </Col>
                
            </Row>
            
    
         </Container>
       
         
         
       </div>
      
          <Footer />
       
          </div>
        
    )
  }
}
export default Login;