import React, { Component } from "react";
import { Container, Row, Col, Card, CardGroup } from "react-bootstrap";
import safeReliable from "../Assets/images/safe-reliable-img1.png";
import "../Css/Express.css";
import trends1 from "../Assets/images/latest-trends-1.png";
import trends2 from "../Assets/images/latest-trends-2.png";
import trends3 from "../Assets/images/latest-trends-3.png";
import dabba from "../Assets/images/dabba.png";
import customsicon from "../Assets/images/customs-icon.svg";
import dailyflight from "../Assets/images/dailyflight-icon.svg";
import trackicon from "../Assets/images/tracking-icon.svg";
import internationalservices from "../Assets/images/interntional-services.png";
import domesticservice from "../Assets/images/domestic-services.png";
import domesticicon from "../Assets/images/fast.svg";
import paymenticon from "../Assets/images/payment-industry.svg";
import returnicon from "../Assets/images/return-industry.svg";
import domesticicon2 from "../Assets/images/collection-industry.svg";
import industrysolution from "../Assets/images/industry-solution-img1.png";
import industrysolution1 from "../Assets/images/industry-solution-img2.png";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
function Express() {
  const history = useHistory();

  //   render() {
  return (
    <div>
      <div className="section-express bg-light  ">
        <div className="express-sect d-lg-block ">
          <Container>
            <Row className="align-items-center  ">
              <Col lg={7} className="bg-light safe-head">
                <div className="safe-content mt-lg-0 mt-4 ">
                  <h2 className="our-company safe-reliable-content ">
                    Safe and reliable
                    <span className="essence"> Logistic solutions.</span>
                  </h2>
                </div>
              </Col>
              <Col lg={5} className="  pe-lg-0 p-0">
                <div className="safe-reliable  d-lg-none  d-block mt-3">
                  <img src={safeReliable} className="img-fluid " />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Industry Solutions */}

        <div className="industry-solution bg-white">
          <Container>
            <Row className="justify-content-center ">
              <Col lg={10} md={12} className="text-center">
                <div className="reliable-head pt-lg-0 pt-4 pb-3">
                  A reliable logistics partners you can trust.
                </div>
                <div className="efficient-sub">
                  Get a seamless service every time you ship your packages with
                  Flip Xpress. Our e-commerce and SME services meet industry
                  challenges head on with reliable and flexible solutions.
                </div>
              </Col>
            </Row>
            <Row className="align-items-center  industry-reponsive ps-1 ps-lg-0">
              <Col lg={6} className="d-lg-block d-none">
                <Row className=" align-items-center   g-0 ">
                  <Col lg={6} md={6} className="pe-lg-1 ">
                    {/* <div className="industry-content">
                                        <img src={industrysolution} className=" industry-img img-fluid" />
                                        <div className=" industry-img-shadow">

                                        </div>
                                    </div> */}

                    <div className="e-commerce industry-content ">
                      <img
                        className="img-fluid ecommerce-image industry-img "
                        src={industrysolution}
                      />
                      <div className="e-commerce-shadow">
                        <h5 className="ecommerce-text industry-text">
                          E commerce
                        </h5>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    className="ps-lg-1 pt-3 pt-lg-0 ps-3 pt-md-0"
                  >
                    <div className="e-commerce industry-content ">
                      <img
                        className="img-fluid ecommerce-image industry-img "
                        src={industrysolution1}
                      />
                      <div className="e-commerce-shadow">
                        <h5 className="ecommerce-text industry-text text-start">
                          Small business solutions
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} className="ps-lg-5 pt-3 pt-lg-0 ">
                <div className="reliable-head pt-2 pb-3 pb-lg-0 pt-lg-0">
                  Industry Solutions
                </div>

                {/* Responsive case */}
                <Col lg={6} className="d-lg-none d-block">
                  <Row className=" align-items-center g-0 ">
                    <Col lg={6} md={6} className="pe-lg-1 px-0">
                      {/* <div className="industry-content">
                                        <img src={industrysolution} className=" industry-img img-fluid" />
                                        <div className=" industry-img-shadow">

                                        </div>
                                    </div> */}

                      <div className="e-commerce industry-content ">
                        <img
                          className="img-fluid ecommerce-image industry-img "
                          src={industrysolution}
                        />
                        <div className="e-commerce-shadow">
                          <h5 className="ecommerce-text industry-text">
                            E commerce
                          </h5>
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      className="ps-lg-1 px-0 pt-3 pt-lg-0 pt-md-0"
                    >
                      <div className="e-commerce industry-content ">
                        <img
                          className="img-fluid ecommerce-image industry-img "
                          src={industrysolution1}
                        />
                        <div className="e-commerce-shadow">
                          <h5 className="ecommerce-text industry-text text-start">
                            Small business solutions
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div className="pt-3">
                  Our logistics services are designed to address the unique
                  requirements of various industries, providing tailored
                  solutions that optimize supply chain operations and drive
                  business success. As your trusted logistics partner, we offer
                  industry-specific expertise and a deep understanding of the
                  challenges and opportunities within different sectors.
                </div>
                <button
                  className="btn industry-solution-readmore my-4"
                  onClick={() => history.push("/contact")}
                >
                  GET IN TOUCH
                </button>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Domestic Services */}
        <div className="domestic-services bg-light">
          <Container>
            <Row className="align-items-center company-main domestic-main">
              <Col lg={6} className="pe-5 ps-lg-0 ps-1">
                <div className="reliable-head pb-4 pb-lg-0 pt-lg-0  ">
                  Domestic Services
                </div>

                <img
                  src={domesticservice}
                  className="img-fluid d-block d-lg-none "
                />
                <div className="pt-4 pt-lg-0">
                  Our domestic logistics services are tailored to meet the
                  transportation and distribution needs within your country.
                  Whether you need to move goods across cities or deliver to
                  remote locations, we have the expertise and infrastructure to
                  ensure efficient and reliable domestic logistics solutions.
                </div>

                <Row>
                  <Col lg={6} md={6}>
                    <div className="domestic-express pt-3">
                      <div className="domestic-content mt-4 mt-lg-0">
                        <div className="domestic-shade"></div>
                        <img src={domesticicon} className="domestic-icon-img" />
                        <h2 className="domestic-head mt-3">Domestic Express</h2>

                        <div className="">
                          Fast and same-day door-to-door shipping you can track
                          in real-time.
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="domestic-express pt-3">
                      <div className="domestic-content mt-md-4 mt-4 mt-lg-0">
                        <div className="domestic-shade collection-shadow"></div>
                        <img
                          src={domesticicon2}
                          className="domestic-icon-img"
                        />
                        <h2 className="domestic-head  mt-3">Collections</h2>

                        <div className="">
                          Doorstep collection for cheques, documents and
                          packages.
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="mt-4 mt-lg-0">
                        <div className="domestic-shade payment-shadow"></div>
                        <img src={paymenticon} className="domestic-icon-img" />
                        <h2 className="domestic-head mt-3">Payment Options</h2>

                        <div className="">
                          Multiple payment options like cash on delivery, card
                          payments and bank transfers.
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="mt-4 mt-lg-0">
                        <div className="domestic-shade collection-shadow"></div>
                        <img src={returnicon} className="domestic-icon-img" />
                        <h2 className="domestic-head  mt-3">Returns</h2>

                        <div className="">
                          Pick-ups and drop-offs at any location for all your
                          returned items.
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={12}>
                    <button className="btn domestic-check  check-rates mt-lg-5">
                      CHECK RATES
                    </button>
                  </Col> */}
                </Row>
              </Col>
              <Col lg={6}>
                <div className="domestic-img-background"></div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Internatinal services */}
        <div className="international-services bg-light">
          <Container>
            <Row className="align-items-center  international-main ">
              <Col lg={5}>
                <div className="international-img-background  ">
                  {/* <img src={internationalservices} className="img-fluid d-none d-lg-block pt-5" /> */}
                </div>
              </Col>
              <Col lg={7} className="ps-lg-5 ps-0 ">
                <div className="reliable-head pt-2 pb-lg-0 pb-4 pt-lg-0">
                  International Services
                </div>

                <img
                  src={internationalservices}
                  className="img-fluid d-lg-none d-block"
                />
                <div className="pt-4 pt-lg-0">
                  Our international logistics services offer comprehensive
                  solutions for businesses looking to expand their reach beyond
                  borders. With our expertise in global shipping and customs
                  regulations, we facilitate the seamless movement of goods
                  across international borders, ensuring timely and secure
                  deliveries to destinations around the world.
                </div>

                <Row>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="domestic-content mt-4 mt-lg-0">
                        <div className="domestic-shade track-shade"></div>
                        <img src={trackicon} className="domestic-icon-img" />
                        <h2 className="domestic-head mt-3">Tracking</h2>

                        <div className="">
                          Online shipping and real-time tracking updates.
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="domestic-content mt-4 mt-md-4 mt-lg-0">
                        <div className="domestic-shade collection-shadow"></div>
                        <img
                          src={domesticicon2}
                          className="domestic-icon-img"
                        />
                        <h2 className="domestic-head  mt-3">Door-To-Door</h2>

                        <div className="">
                          International deliveries in 2-3 days.
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="mt-4 mt-lg-0">
                        <div className="domestic-shade payment-shadow"></div>
                        <img src={dailyflight} className="domestic-icon-img" />
                        <h2 className="domestic-head mt-3">Daily Flights</h2>

                        <div className="">
                          Faster shipments with direct daily & weekly flights.
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="domestic-express">
                      <div className="mt-4 mt-lg-0">
                        <div className="domestic-shade collection-shadow"></div>
                        <img src={customsicon} className="domestic-icon-img" />
                        <h2 className="domestic-head  mt-3">Customs</h2>

                        <div className="">
                          Full customs clearance and optional insurance.
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={12}>
                    <button className="btn check-rates mt-lg-5 my-md-5">
                      CHECK RATES
                    </button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Launching soon Dabba. */}

        <div className="bg-white">
          <Container>
            <Row className="align-items-center pt-5">
              <Col md={5}>
                <img className="img-fluid" src={dabba}></img>
              </Col>
              <Col md={7} className="order-lg-last order-first">
                <div className="Launchin-view">
                  <h2 className=" launching ">Launching soon</h2>
                  <div className=" essance dabba pb-lg-5 pb-3">Dabba.</div>
                  <p className="launching-content py-3">
                    Have your shopping delivered from the US, UK and many other
                    Dabba locations.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Value added services */}
        <div className="value-added-service " style={{ background: "#9ACE21" }}>
          <Container>
            <Row className=" text-center justify-content-center">
              <Col lg={10}>
                <div className="value-added-service-content">
                  <div className="reliable-head value-added-head">
                    Value added <span className="">services</span>
                  </div>
                  <div className="value-added-para">
                    Find out how we can take care of repacking, labeling, price
                    tagging and kitting your packages.
                  </div>

                  <button
                    className="btn value-added-touch mt-5"
                    onClick={() => history.push("/contact")}
                  >
                    GET IN TOUCH
                  </button>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Latest Trends */}
          {/* <div className="latest-trends bg-white">
            <Container>
              <div className="latest-content company-main">
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center">
                    <div className="reliable-head pt-lg-5 pb-2 pb-lg-3">
                      The latest trends
                    </div>
                  </Col>
                </Row>

                <CardGroup className=" mt-3">
                  <Card className="me-lg-4 trend-card mt-4 mt-lg-0 ">
                    <Card.Img
                      variant="top"
                      src={trends1}
                      className="latest-trend"
                    />
                    <Card.Body>
                      <Card.Title className="trends-content">
                        10 powerful tips from SME businesses that turned unicorn
                      </Card.Title> */}
          {/* <Card.Text>
                                                Flip Xpress
                                                </Card.Text> */}
          {/* </Card.Body>
                    <Card.Footer className="content-trends ">
                      <small className="text-muted"> Flip Xpress</small>
                      <br />
                      <small className="text-muted"> Aug 12, 2023</small>
                    </Card.Footer>
                  </Card>
                  <Card className="me-lg-4 trend-card trend-card2 mt-4 mt-lg-0">
                    <Card.Img
                      variant="top"
                      src={trends2}
                      className="latest-trend"
                    />
                    <Card.Body>
                      <Card.Title className="trends-content">
                        3 must know strategies to optimize your operations for
                        business growth.
                      </Card.Title> */}
          {/* <Card.Text>
                                                Flip Xpress
                                                </Card.Text> */}
          {/* </Card.Body>
                    <Card.Footer className="content-trends">
                      <small className="text-muted"> Flip Xpress</small>
                      <br />
                      <small className="text-muted"> Aug 12, 2023</small>
                    </Card.Footer>
                  </Card>
                  <Card className="me-lg-4 trend-card trend-card2 mt-4 mt-lg-0">
                    <Card.Img
                      variant="top"
                      src={trends3}
                      className="latest-trend"
                    />
                    <Card.Body>
                      <Card.Title className="trends-content">
                        5 must know tips for successful SME brand partnerships.
                      </Card.Title> */}
          {/* <Card.Text>
                                                Flip Xpress
                                                </Card.Text> */}
          {/* </Card.Body>
                    <Card.Footer className="content-trends">
                      <small className="text-muted"> Flip Xpress</small>
                      <br />
                      <small className="text-muted"> Aug 12, 2023</small>
                    </Card.Footer>
                  </Card>
                </CardGroup>
              </div>
            </Container>
          </div> */}
          <Footer />
        </div>
      </div>
    </div>
  );
  //   }
}
export default Express;
