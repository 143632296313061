import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import specialservices from "../Assets/images/special-services1.png";
import courier from "../Assets/images/courier-service.png";
import fulfilment from "../Assets/images/fulfilment.svg";
import codremittance from "../Assets/images/services-cod.svg";
import someday from "../Assets/images/someday-fast.svg";
import lastflip from "../Assets/images/lastflip.svg";
import effortless from "../Assets/images/effortless-img.png";
import crossborder from "../Assets/images/cross-border.svg";
import flipdrop from "../Assets/images/flipdrop.svg";
import customerinitiated from "../Assets/images/customer-initiated.svg";
import globalreach from "../Assets/images/global-reach.svg";
import whyflip from "../Assets/images/whyflip.png";
import "../Css/Services.css";
import transperency from "../Assets/images/transperency.svg";
import premium from "../Assets/images/premium.svg";
import efficiency from "../Assets/images/efficiency.svg";
import ServicesCarousal from "./ServicesCarousal";
import Footer from "./Footer";
import ecommerce from "../Assets/images/who-we-support-img.png"
import retailers from "../Assets/images/retailers-carousal.png";
import socialmedia from "../Assets/images/social-media-carousal.png";
import otherverticals from "../Assets/images/other-verticals-carousal.png";


class Services extends Component {
    render() {
        return (

            <div className="services-section ">
                <div className="services-sect bg-white d-lg-block ">
                    <Container >
                        <Row className="align-items-center  ">
                            <Col lg={7} className="bg-white safe-head" >
                                <div className="safe-content mt-lg-0 mt-4">
                                    <h2 className="our-company safe-reliable-content">Seamless Solutions<span className="essence"> E-commerce Success</span></h2>

                                </div>
                            </Col>
                            <Col lg={5} className=" order-lg-last   pe-lg-0 p-0">
                                <div className="safe-reliable d-lg-none  d-block mt-3" >

                                    <img src={specialservices} className="img-fluid  " />


                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Why Choose Our E-Commerce Courier Services */}
                <div className="customer-security-section industry-solution  bg-light">
                    <Container >
                        <Row className="justify-content-center">
                            <Col lg={12} className="text-center" >
                                <div className="reliable-head efficient-head  pt-lg-0 pt-3 pb-lg-0">Why Choose Our E-Commerce Courier Services</div>

                            </Col>
                        </Row>
                        <Row className="align-items-center  ">
                            <Col lg={5}>
                                <div className="rapid-solution-img  mt-lg-5">

                                </div>
                            </Col>
                            <Col lg={7} className="ps-lg-5 ps-3 py-4 py-lg-0 mt-lg-5" >
                                <div className="reliable-head rapid-solution pb-4 pb-lg-0 ">Rapid Solutions</div>
                                <img src={courier} className="img-fluid d-lg-none d-block" />


                                <Row>
                                    <Col lg={6} md={6}>
                                        <div className="domestic-express pt-4 pt-lg-0">
                                            <div className="domestic-content ">
                                                <div className="domestic-shade insurance-shade "></div>
                                                <img src={codremittance} className="domestic-icon-img" />
                                                <h2 className="domestic-head mt-4">Quick COD Remittance</h2>

                                                <div className="">Choose cash on delivery for added convenience. You’ll receive your COD payment promptly.</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <div className="domestic-express pt-4 pt-lg-0">
                                            <div className="domestic-content ">
                                                <div className="domestic-shade insurance-shade"></div>
                                                <img src={fulfilment} className="domestic-icon-img" />
                                                <h2 className="domestic-head  mt-4">Fulfilment And Warehousing</h2>

                                                <div className="">We offer precise, global warehousing and fulfillment tailored to your needs.</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6} md={6}>
                                        <div className="domestic-express">
                                            <div className="domestic-content pt-4 pt-lg-0">
                                                <div className="domestic-shade insurance-shade ms-5"></div>
                                                <img src={someday} className="domestic-icon-img" />
                                                <h2 className="domestic-head mt-4">Same Day Next Day</h2>

                                                <div className="">Swift same-day, next-day, and scheduled deliveries available.</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <div className="domestic-express">
                                            <div className="domestic-content pt-4 pt-lg-0">
                                                <div className="domestic-shade insurance-shade"></div>
                                                <img src={lastflip} className="domestic-icon-img" />
                                                <h2 className="domestic-head  mt-4">Last-Flip</h2>

                                                <div className="">We handle last-mile deliveries from your warehouse to your customers.</div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>


                            </Col>



                        </Row>

                    </Container>

                    {/* Effortless Solutions */}
                    <div className="effortless-section  ">
                        <Container className="effort">
                            <Row className="align-items-center pb-4 pb-lg-0  ">

                                <Col lg={7} className="pt-lg-5 ps-3 ps-lg-0 mt-lg-5">
                                    <div className="reliable-head rapid-solution my-3 mt-lg-0">Effortless Solutions</div>

                                    <img src={effortless} className="img-fluid d-lg-none d-block" />

                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div className="domestic-express pt-4 pt-lg-0">
                                                <div className="domestic-content ">
                                                    <div className="domestic-shade insurance-shade"></div>
                                                    <img src={crossborder} className="domestic-icon-img" />
                                                    <h2 className="domestic-head mt-4">Cross border & Custom Clearance</h2>

                                                    <div className="">Streamlined global logistics, including cross-border pickup and express customs clearance.</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <div className="domestic-express pt-4 pt-lg-0">
                                                <div className="domestic-content  ">
                                                    <div className="domestic-shade insurance-shade"></div>
                                                    <img src={flipdrop} className="domestic-icon-img" />
                                                    <h2 className="domestic-head  mt-4">Flip Drop</h2>

                                                    <div className="">Effortless order pickup and drop-off with the Flip Xpress Delivery App</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div className="domestic-express">
                                                <div className="domestic-content  pt-4 pt-lg-0">
                                                    <div className="domestic-shade insurance-shade"></div>
                                                    <img src={customerinitiated} className="domestic-icon-img" />
                                                    <h2 className="domestic-head mt-4">Customer Initiated Returns</h2>

                                                    <div className="">Simple returns scheduling with just a click through our platform.</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6}>
                                            <div className="domestic-express">
                                                <div className="domestic-content  pt-4 pt-lg-0">
                                                    <div className="domestic-shade insurance-shade"></div>
                                                    <img src={globalreach} className="domestic-icon-img" />
                                                    <h2 className="domestic-head  mt-4">Global Reach</h2>

                                                    <div className="">Unlock global opportunities with our expansive logistics network. Explore our services today.</div>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>


                                </Col>
                                <Col lg={5}>
                                    <div className=" effortless-img-background mt-lg-5"></div>

                                </Col>


                            </Row>
                        </Container>
                    </div>
                </div>


                {/* Why flip */}
                <Container fluid>

                    <Row className="g-0">
                        <Col lg={6} className="p-0">
                            <div className="fxp-express why-flip-background d-none d-lg-block">

                            </div>
                            <img className="img-fluid d-lg-none d-block " src={whyflip} style={{ width: '100%' }} />
                        </Col>
                        <Col lg={6} className="p-0 freight-log-side" >

                            <div className="freight-logistics ">

                                <div className="why-flip">
                                    <div className="reliable-head">Why Flip?</div>
                                    <Row className="g-0 align-items-center pt-3 pt-lg-5" >
                                        <Col lg="auto" xs="auto" >
                                            <div className="premium-color">
                                                <img src={premium} />
                                            </div>

                                        </Col>
                                        <Col lg>
                                            <h2 className="domestic-head premium-performance mt-3">Premium performance</h2>
                                            <div className="">Our clients, big and small, will have all there logistics needs met as we provide customized processes.</div>
                                        </Col>
                                    </Row>
                                    <Row className="g-0 align-items-center pt-3 pt-lg-5" >
                                        <Col lg="auto" xs="auto">
                                            <div className="premium-color transperency">
                                                <img src={transperency} className=" " />
                                            </div>

                                        </Col>
                                        <Col lg>
                                            <h2 className="domestic-head premium-performance mt-3">Transparency</h2>
                                            <div className="">Our digitized processes and client portals give complete visibility on the performance of your businesses.</div>
                                        </Col>
                                    </Row>
                                    <Row className="g-0 align-items-center pt-3 pt-lg-5" >
                                        <Col lg="auto" xs="auto">
                                            <div className="premium-color">
                                                <img src={efficiency} className=" " />
                                            </div>

                                        </Col>
                                        <Col lg className="">
                                            <h2 className="domestic-head premium-performance mt-3 ">Efficiency</h2>
                                            <div className="">Our in house technology ensures enhanced customer and client experience with exceptional performance.</div>
                                        </Col>
                                    </Row>



                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>


                {/* Who We Support */}
                <div className="who-we-support industry-solution">
                    <Container >
                        <Row className="justify-content-center">
                            <Col lg={9} className="text-center" >
                                <div className="reliable-head efficient-head pt-lg-0 pt-3 pb-3">Who We Support</div>

                            </Col>
                        </Row>



                    </Container>


                    <div className="d-block d-lg-none ">
                        <Row className="align-items-center g-0">
                            <Col md={6} className="p-0">
                                <Card className="px-md-2 pt-md-3 px-4  whowesupport-box " >
                                    <Card.Img variant="top" src={ecommerce} className="whowesupport-img img-fluid" />
                                    <Card.Body className="whosupport-content">
                                        <Card.Title>E-commerce and Aggregators</Card.Title>
                                        <Card.Text>
                                            Flip Xpress covers all your 3PL e-commerce requirements from pick up to warehousing and drop off.
                                        </Card.Text>

                                    </Card.Body>

                                </Card>
                            </Col>

                            <Col md={6} className="p-0">
                                <Card className="px-md-2 pt-md-3 px-4 pt-3 whowesupport-box ">
                                    <Card.Img variant="top" src={retailers} className="whowesupport-img img-fluid" />
                                    <Card.Body className="whosupport-content">
                                        <Card.Title>Retailers</Card.Title>
                                        <Card.Text>
                                            Our perfect balance of tech and data allows us to understand the needs of our e-commerce landscape better.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="align-items-center g-0" >

                        <Col md={6} className="p-0">
                                <Card className="px-md-2 pt-md-3 px-4 pt-3 pb-md-4  whowesupport-box">
                                    <Card.Img variant="top" src={socialmedia} className="whowesupport-img img-fluid" />
                                    <Card.Body className="whosupport-content">
                                        <Card.Title>Social Media Businesses</Card.Title>
                                        <Card.Text>
                                        Flip Xpress provides cost-effective solutions to even the smaller <br/> players.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} className="p-0">
                                <Card className="px-md-2 pt-md-3 px-4 pt-3 pb-4 pb-lg-0  whowesupport-box">
                                    <Card.Img variant="top" src={otherverticals} className="whowesupport-img img-fluid" />
                                    <Card.Body className="whosupport-content">
                                        <Card.Title>Other Verticals</Card.Title>
                                        <Card.Text>
                                            Our value proposition goes beyond traditional delivery services—emerging markets and beyond.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="d-lg-block d-none ">
                        <ServicesCarousal />
                    </div>
                </div>

                <Footer />
            </div>

        )
    }
}
export default Services