import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Goals() {
  return (
    <div className="our-goals-number">
      <Row className="justify-content-center text-center goals-content">
        <Col md="auto" className="ps-3 ps-lg-5">
          <div className="deliver-complete ">
            <h2 className="goals-count">500k+</h2>
            <div className="deliver-content">Deliveries Completed</div>
          </div>
        </Col>
        <Col md="auto" className=" ps-3 ps-lg-5">
          <div className="deliver-complete ">
            <h2 className="goals-count">350+</h2>
            <div className="deliver-content">Happy Clients</div>
          </div>
        </Col>
        <Col md="auto" className=" ps-3 ps-lg-5">
          <div className="deliver-complete">
            <h2 className="goals-count">2000+</h2>
            <div className="deliver-content">Shipments Daily</div>
          </div>
        </Col>
        <Col md="auto" className="ps-3 ps-lg-5">
          <div className="deliver-complete  ">
            <h2 className="goals-count">20+</h2>
            <div className="deliver-content">Year’s In Market</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
