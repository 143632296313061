import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "../Css/ServicesCarousal.css";


export default function ServicesCarousal() {
  useEffect(() => {
    let touchStartX = 0; // Declare touchStartX
    let touchDeltaX = 0; // Declare touchDeltaX

    function initializeCarousel() {
      const content1 = document.getElementById("scroll-content1");
      const leftButton1 = document.querySelector(".left-button1");
      const rightButton1 = document.querySelector(".right-button1");
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      function updateButtonState1() {
        leftButton1.disabled = content1.scrollLeft <= 0;
        rightButton1.disabled =
          content1.scrollLeft >= content1.scrollWidth - content1.clientWidth;
      }

      leftButton1.addEventListener("click", function () {
        content1.scrollLeft -= 1160;
        updateButtonState1();
      });

      rightButton1.addEventListener("click", function () {
        content1.scrollLeft += 1160;
        updateButtonState1();
      });

      content1.addEventListener("scroll", () => {
        updateButtonState1();
      });

      content1.addEventListener("mousedown", (e) => {
        isDragging = true;
        startX = e.pageX - content1.offsetLeft;
        scrollLeft = content1.scrollLeft;
      });

      content1.addEventListener("mouseleave", () => {
        isDragging = false;
      });

      content1.addEventListener("mouseup", () => {
        isDragging = false;
      });

      content1.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - content1.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scrolling speed
        content1.scrollLeft = scrollLeft - walk;
        updateButtonState1();
      });

      content1.addEventListener("touchstart", (e) => {
        touchStartX = e.touches[0].clientX;
        touchDeltaX = 0;
      });

      content1.addEventListener("touchmove", (e) => {
        const touchMoveX = e.touches[0].clientX;
        touchDeltaX = touchStartX - touchMoveX;
        touchStartX = touchMoveX;
        content1.scrollLeft += touchDeltaX;
        updateButtonState1();
      });

      content1.addEventListener("touchend", () => {
        // Apply additional easing or inertial scrolling if desired
      });

      const scrollContainers = document.querySelectorAll(".scroll-container");

      scrollContainers.forEach((container) => {
        const scrollContent = container.querySelector(".scroll-content");
        const scrollBar = container.querySelector(".scroll-bar");

        function updateScrollBar() {
          const scrollPercentage =
            (scrollContent.scrollLeft /
              (scrollContent.scrollWidth - scrollContent.clientWidth)) *
            100;
          scrollBar.style.width = `${scrollPercentage}%`;
        }

        scrollContent.addEventListener("scroll", updateScrollBar);
        updateScrollBar();
      });
    }

    initializeCarousel();
  }, []); // The empty array [] means this effect runs only on mount


  return (
    <section className="">
      <Container fluid>
        <div className="scroll-container">
          <button className="scroll-button left-button left-button1">&larr;</button>
          <div className="scroll-content" id="scroll-content1">
            <div className="scroll-item">
              <Row className=" g-0 who-we-support-box text-center ms-5">
                <Col lg={7} xs={6} className="p-0 who-support">
                  <div className="who-we-support-content ">
                    <div className="e-commerce-text">
                      E-commerce and Aggregators
                    </div>
                    <div className="e-commerce-para pt-3">
                      Flip Xpress covers all your 3PL e-commerce requirements from
                      pick up to warehousing and drop off.
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={6} className="p-0 whowe-support "></Col>
              </Row>
            </div>

            <div className="scroll-item">
              <Row className=" g-0 who-we-support-box text-center">
                <Col lg={7} xs={6} className="p-0 who-support">
                  <div className="who-we-support-content ">
                    <div className="e-commerce-text">
                      Retailers
                    </div>
                    <div className="e-commerce-para pt-3">
                      Our perfect balance of tech and data allows us to understand the needs of our e-commerce landscape better.
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={6} className="p-0 whowe-support retailers"></Col>
              </Row>
            </div>
            <div className="scroll-item">
              <Row className=" g-0 who-we-support-box text-center">
                <Col lg={7} xs={6} className="p-0 who-support">
                  <div className="who-we-support-content ">
                    <div className="e-commerce-text">
                    Social Media Businesses
                    </div>
                    <div className="e-commerce-para pt-3">
                    Flip Xpress provides cost-effective solutions to even the smaller players.
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={6} className="p-0 whowe-support social-media"></Col>
              </Row>
            </div>
            <div className="scroll-item">
              <Row className=" g-0 who-we-support-box text-center">
                <Col lg={7} xs={6} className="p-0 who-support">
                  <div className="who-we-support-content ">
                    <div className="e-commerce-text">
                    Other Verticals
                    </div>
                    <div className="e-commerce-para pt-3">
                    Our value proposition goes beyond traditional delivery services—emerging markets and beyond.
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={6} className="p-0 whowe-support other-verticals"></Col>
              </Row>
            </div>







          </div>
          <button className="scroll-button right-button right-button1">
            &rarr;
          </button>

          <div className="scroll-bar-container">
            <div className="scroll-bar"></div>
          </div>
        </div>
      </Container>
 
    </section>

  )
}