import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Signupform from "./Signupform";
import signup from "../Assets/images/signup-xp.png";
import Footer from "./Footer";
import "../Css/Signup.css";

 class Signup extends Component{
   render(){
    return(
        <div>
           <div className="sign-up-section">
         <Container  >
            <Row >
                <Col lg={6} >
                   <div className="login-content signup-content company-main">
                  <div className="login-head">Hi, We are<span className="essence"> Flip Xpress</span></div>
                  <div className="login-para pt-3">Please create your account</div>
                  <Signupform/>
                   </div>
                </Col>
                <Col lg={6} className="sign-up-img login-img d-none d-lg-block">
                <div className="signup-content-img">
                    <div className="login-text-content texts-poppins-bold">“The Line Between Disorder And Order Lies In Logistics…”</div>
                    <div className="login-text-content  login-text2">-Sun Tzu</div>
                    </div>
                </Col>
            </Row>
         </Container>
       
         
      
        </div>
        <Footer />
        </div>
    )
   }
 }
 export default Signup;