import React, { Component } from "react";
import "../Css/Contactform.css";
import axios from "axios";
class Contactform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
    this.handleName = this.handleName.bind(this);
    this.handleMail = this.handleMail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleMail(event) {
    this.setState({ email: event.target.value });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleSubmit(event) {
    // alert('You have submitted the input successfully: ' + this.state.value);
    event.preventDefault();
    console.log(this.state);

    axios
      .post("https://ship.flipxp.com/api/v1/contact_us", {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      })
      .then((res) => {
        console.log(res.data.message);
        document.getElementById("contact-form").reset();
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <form id="contact-form" onSubmit={this.handleSubmit}>
        <input
          type="text"
          onChange={this.handleName}
          placeholder="Your Name "
          className="p-3 my-3 bg-light name-form"
        />
        <br />
        <input
          type="email"
          onChange={this.handleMail}
          placeholder="Your Mail"
          className="p-3 my-3 bg-light  name-form"
        />
        <br />
        <textarea
          type="text"
          onChange={this.handleMessage}
          placeholder="Message"
          className="p-3  my-3 bg-light  name-form message-form"
        />
        <br />
        <button
          className="btn  check-rates px-5 my-3 my-lg-0 mt-lg-3"
          type="submit"
          value="Submit"
        >
          SUBMIT
        </button>
      </form>
    );
  }
}
export default Contactform;
