import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../Css/TestimonialCarousal.css";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import testimonial1 from "../Assets/images/flipxp-user.png";
import testimonial2 from "../Assets/images/flipxp-user.png";
import testimonial3 from "../Assets/images/flipxp-user.png";
import testimonial4 from "../Assets/images/flipxp-user.png";

export default function TestimonialCarousal() {
  useEffect(() => {
    let touchStartX = 0; // Declare touchStartX
    let touchDeltaX = 0; // Declare touchDeltaX

    function initializeCarousel() {
      const content1 = document.getElementById("scroll-content1");
      const leftButton1 = document.querySelector(".left-button1");
      const rightButton1 = document.querySelector(".right-button1");
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      function updateButtonState1() {
        leftButton1.disabled = content1.scrollLeft <= 0;
        rightButton1.disabled =
          content1.scrollLeft >= content1.scrollWidth - content1.clientWidth;
      }

      leftButton1.addEventListener("click", function () {
        content1.scrollLeft -=370;
        updateButtonState1();
      });

      rightButton1.addEventListener("click", function () {
        content1.scrollLeft += 370;
        updateButtonState1();
      });

      content1.addEventListener("scroll", () => {
        updateButtonState1();
      });

      content1.addEventListener("mousedown", (e) => {
        isDragging = true;
        startX = e.pageX - content1.offsetLeft;
        scrollLeft = content1.scrollLeft;
      });

      content1.addEventListener("mouseleave", () => {
        isDragging = false;
      });

      content1.addEventListener("mouseup", () => {
        isDragging = false;
      });

      content1.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - content1.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scrolling speed
        content1.scrollLeft = scrollLeft - walk;
        updateButtonState1();
      });

      content1.addEventListener("touchstart", (e) => {
        touchStartX = e.touches[0].clientX;
        touchDeltaX = 0;
      });

      content1.addEventListener("touchmove", (e) => {
        const touchMoveX = e.touches[0].clientX;
        touchDeltaX = touchStartX - touchMoveX;
        touchStartX = touchMoveX;
        content1.scrollLeft += touchDeltaX;
        updateButtonState1();
      });

      content1.addEventListener("touchend", () => {
        // Apply additional easing or inertial scrolling if desired
      });

      const scrollContainers = document.querySelectorAll(".scroll-container");

      scrollContainers.forEach((container) => {
        const scrollContent = container.querySelector(".scroll-content");
        const scrollBar = container.querySelector(".scroll-bar");

        function updateScrollBar() {
          const scrollPercentage =
            (scrollContent.scrollLeft /
              (scrollContent.scrollWidth - scrollContent.clientWidth)) *
            100;
          scrollBar.style.width = `${scrollPercentage}%`;
        }

        scrollContent.addEventListener("scroll", updateScrollBar);
        updateScrollBar();
      });
    }

    initializeCarousel();
  }, []); // The empty array [] means this effect runs only on mount

  return (
    <section className="bg-light pb-lg-5">
      <Container>
        <div className="scroll-container p-5 ">
          <button className="scroll-button left-button left-button1">&larr;</button>
          <div className="scroll-content  p-5" id="scroll-content1">
            <div className="scroll-item text-center">
          

                

                  <div className="pb-5 testimonial-content" >
                    <div className="testimonial-path bg-white p-4  " style={{  minHeight: "430px"}}>
                      <img src={testimonial1} className="img-fluid pb-3" width="50px" height="50px" />
                      <div className="jordan">Rahul Swaminathan</div>
                      <div className="ceo-mantis">C.E.O & Founder of Desertcart</div>
                      <p className="pt-3 px-3">“I have been dealing with FXP for the past 4 years, they are life savers,They have support supported my business in multiple 
                      situations, very supportive, good about them is they helped us in customizing my logistics."
                      <br/><br/>Highly Recommended!</p>
                   </div>
                  </div>
               
          

            </div>

            <div className="scroll-item text-center">
             

                  <div className="pb-5 testimonial-content">
                    <div className="testimonial-path bg-white  p-4" style={{  minHeight: "430px"}}>
                      <img src={testimonial2} className="img-fluid pb-3" width="50px" height="50px" />
                      <div className="jordan">Abubot Atpb</div>
                      <div className="ceo-mantis">Owner of Busy Bee</div>
                      <p className="pt-3 px-3">“Had a great experience with Flip, support my business by providing best service, available almost all the time even after 12:00 midnight someone is there to respond your query.”</p>
                    </div>
                  </div>
             

            </div>

            <div className="scroll-item text-center">
            
              
            <div className="pb-5 testimonial-content">
              <div className="testimonial-path bg-white  p-4 " style={{  minHeight: "430px"}}>
                <img src={testimonial3} className="img-fluid pb-3" width="50px" height="50px" />
                <div className="jordan">Mustapha</div>
                <div className="ceo-mantis">C.E.O. of Makfool.ae</div>
                <p className="pt-3 px-3">“Service is the key aspect of any business, started with Flip very low now my business flourishes and nourishes.”</p>
              </div>
            </div>

  
      </div>

            <div className="scroll-item text-center ">

           
                  <div className="pb-5 testimonial-content">
                    <div className="testimonial-path bg-white  p-4" style={{  minHeight: "430px"}}>
                      <img src={testimonial4} className="img-fluid pb-3 " width="50px" height="50px" />
                      <div className="jordan">Ali Dealizle</div>
                      <div className="ceo-mantis">CEO & Founder of Qazada.com</div>
                      <p className="pt-3 px-3">“Highly Recommended!!”</p>
                   
                    </div>


                  </div>
             

            </div>
     


          </div>

          
          <button className="scroll-button right-button right-button1">
            &rarr;
          </button>

          <div className="scroll-bar-container testimonial-scroll">
            <div className="scroll-bar"></div>
          </div>
        </div>
        
      </Container >
 
    </section >
  );
}
