import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Css/Footer.css";
import { useHistory } from "react-router-dom";
import logofooter from "../Assets/images/logo-footer.png";

export default function Footer() {
  const history = useHistory();
  const navigateHome = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const navigateExpress = () => {
    history.push("/express");
    window.scrollTo(0, 0);
  };
  const navigateLogistics = () => {
    history.push("/logistics");
    window.scrollTo(0, 0);
  };
  const navigateServices = () => {
    history.push("/services");
    window.scrollTo(0, 0);
  };
  const navigateAbout = () => {
    history.push("/aboutus");
    window.scrollTo(0, 0);
  };
  const navigateContact = () => {
    history.push("/contact");
    window.scrollTo(0, 0);
  };
  const navigateTrack = () => {
    history.push("/track");
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* Footer section */}

      <div className="footer-sect text-white pt-5 ">
        <Container>
          <Row className="">
            <Col lg={4} md={12} className="">
              <h6 className=" mb-4">
                <img src={logofooter} />
              </h6>
            </Col>
            <Col lg={5} md={6} className="my-2 text-light">
              <h6 className="mb-4 links-head">Quick Links</h6>
              <Row>
                <Col lg={6} md="auto">
                  <p>
                    <a className="links-footer" onClick={navigateHome}>
                      Home
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" onClick={navigateExpress}>
                      Express
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" onClick={navigateLogistics}>
                      Logistics
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" onClick={navigateServices}>
                      Special Services
                    </a>
                  </p>
                </Col>
                <Col lg={6} md="auto">
                  <p>
                    <a className="links-footer" onClick={navigateAbout}>
                      About Us
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" onClick={navigateContact}>
                      Contact Us
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" onClick={navigateTrack}>
                      Track
                    </a>
                  </p>
                  <p>
                    <a className="links-footer" href="https://ship.flipxp.com/">
                      Login
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={6} className="my-2 text-light">
              <h6 className="mb-4 links-head">Address</h6>

              <p>
                Warehouse #7, 7A Street, Behind Promar LLC, Umm Ramool, Dubai
                U.A.E.
              </p>
              <button
                className="btn get-direction mb-3"
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/dir//25.2298847,55.360753/@25.2298847,55.360753,14z?entry=ttu"
                  );
                }}
              >
                GET DIRECTION
              </button>
              <div className="align-items-center d-flex pt-2">
                <i className="ri-phone-fill"></i>
                <a href="tel:+97143249993">
                  <div className="ps-2 ">+971 4 324 9993</div>
                </a>
              </div>
              <div className="align-items-center d-flex pt-2">
                <i className="ri-mail-line "></i>
                <a href="mailto:atyourservice@flipxp.com">
                  <div className="ps-2 ">atyourservice@flipxp.com</div>
                </a>
              </div>
            </Col>
            <div className="copy text-center mt-5"></div>
            <Col lg={6}>
              <p className="copyright-content-1">
                Copyright @ {new Date().getFullYear()}. All rights reserved by{" "}
                <span style={{ color: "#9ACE21" }}>FlipXP.</span>
              </p>
            </Col>
            <Col lg={6}>
              <p className="copyright-content-2">
                Developed by{" "}
                <a href="https://www.hashinclude.io/">
                  <span style={{ color: "#9ACE21" }}>HASH INCLUDE IO</span>
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
