import React, { Component, useState } from "react";
import eye from "../Assets/images/eye-line.png";
import eyeOff from "../Assets/images/eye-off-line.png";
import "../Css/Signupform.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Signupform() {
  const history = useHistory();
  const signup = (e) => {
    history.push(e);
  };
  const login = (e) => {
    history.push(e);
  };
  // States for registration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [number, setNum] = useState("");
  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
    setSubmitted(false);
  };
  const handleNum = (e) => {
    setNum(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || password === "") {
      setError(true);
    } else {
      setSubmitted(true);
      setError(false);
    }
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h1>User {name} successfully registered!!</h1>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none",
        }}
      >
        <h1>Please enter all the fields</h1>
      </div>
    );
  };

  return (
    <div className="signup-form">
    <div className="form">
      {/* Calling to the methods */}
      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>
   

        <form>
          {/* Labels and inputs for form data */}

          <input
            onChange={handleName}
            className="input p-3 my-3 bg-light  name-form login-form"
            placeholder="Full Name"
            value={name}
            type="text"
          />
          <input
            onChange={handleNum}
            className="input p-3  bg-light  name-form login-form"
            placeholder="Phone Number"
            value={number}
            type="number"
          />

          <input
            onChange={handleEmail}
            className="input p-3 mt-3 bg-light  name-form login-form"
            placeholder="Email"
            value={email}
            type="email"
          />

          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            className="input p-3 my-3 bg-light  name-form login-form"
            placeholder="Password"
            type={type}
          />
          <span
            className="flex justify-around items-center signup-icon"
            onClick={handleToggle}
          >
            <img src={icon} />
          </span>
          {/* 
                <button onClick={handleSubmit} className="btn" type="submit">
             
                </button> */}
          <input
            type="submit"
            value="SUBMIT"
            className="login-text login-button mt-5 "
            onClick={() => signup("/signup")}
          />
          <div className="text-center pt-3">
            Already have an account?
            <span>
              <button onClick={() => login("/login")} className="sign-up">
                Login
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signupform;
