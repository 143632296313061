import React, { Component, useEffect, useState } from "react";
import trackdotted from "../Assets/images/track-dotted.png";
import "../Css/Track.css";
import {
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Tabs,
  Tab,
} from "react-bootstrap";
import Footer from "./Footer";
import axios from "axios";
import moment from "moment";
import trackingImage from "../Assets/images/tracking-image.svg";
import trackingNoData from "../Assets/images/tracking-nodata.svg";
import { useLocation } from "react-router-dom";

function Track() {
  const location = useLocation();
  const [trackID, setTrackID] = useState("");
  const [trackData, setTrackData] = useState([]);
  const [api, setApi] = useState(false);

  // render() {

  useEffect(() => {
    console.log(trackData);
  }, [trackData]);

  const handleTrackIDChange = (e) => {
    let str = e.target.value.split(/[ ,]+/).join(",");
    setTrackID(str);
  };

  const trackClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    axios
      .get(
        `https://ship.flipxp.com/apis/manifest_status_multiple?awb_number=${trackID}`
      )
      .then((resp) => {
        setTrackData(resp.data.data);
        setApi(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (location.state && location.state.trackKey) {
      let key = location.state.trackKey;
      if (key) {
        setTrackID(key);
        axios
          .get(
            `https://ship.flipxp.com/apis/manifest_status_multiple?awb_number=${key}`
          )
          .then((resp) => {
            setTrackData(resp.data.data);
            setApi(true);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  return (
    <>
      <section>
        <div className="track-dotted">
          <img src={trackdotted} className="track-dotted-img " />
        </div>
        <div className="track-formed">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9} md={12}>
                <div className="track-search-form">
                  <Form className="d-flex track-id-form">
                    <FormControl
                      type="search"
                      placeholder="Enter your track ID"
                      className="me-2"
                      aria-label="Track"
                      value={trackID}
                      onChange={handleTrackIDChange}
                    />
                    <button
                      variant="outline-success"
                      className="btn tracker"
                      onClick={trackClick}
                    >
                      Track
                    </button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className=" py-5">
              <div className="bg-light">
                {!api && trackData.length === 0 && (
                  <Row className="py-4">
                    <img
                      alt="track-image"
                      src={trackingImage}
                      height={350}
                    ></img>
                    <div className="text-center px-lg-0 px-5">
                      <div className="status-detail-item"></div>

                      <h2 className="details fontfamily pt-lg-4">
                        Track Your Orders Easily
                      </h2>

                      <div className="created">
                        Just enter your AWB tracking number or Order ID & it’s
                        done.
                      </div>
                      <div className="status-detail-item"></div>
                      <div className="status-detail-item"></div>
                    </div>
                  </Row>
                )}
                {api && trackData.length === 0 && (
                  <Row className="py-4">
                    <img
                      alt="track-image"
                      src={trackingNoData}
                      height={250}
                      className=""
                    ></img>
                    <div className="text-center">
                      <div className="status-detail-item"></div>

                      <h2 className="details fontfamily">No Results </h2>
                      <div className="status-detail-item"></div>

                      <div className="created">Please enter a valid AWB ID</div>
                      <div className="status-detail-item"></div>
                      <div className="status-detail-item"></div>
                    </div>
                  </Row>
                )}
                {api && trackData.length >= 0 && (
                  <Tabs hidden={trackData.length <= 0} className="track-tabs">
                    {trackData.map((data) => (
                      <Tab eventKey={data.awb_number} title={data.awb_number}>
                        <Row>
                          <Col lg={6} md={4}>
                            <div className="order-details-part order-main order-status">
                              <div className="text-start">
                                <h2 className="details fontfamily">
                                  Order Status
                                </h2>
                              </div>
                              <div className=" half-a-border-on-left">
                                {data.tracking &&
                                  data.tracking.map((t, i) => {
                                    if (i === 0) {
                                      return (
                                        <>
                                          <div className="status-item">
                                            <div className="status-badge status-created">
                                              <i className="ri-check-line checked"></i>
                                            </div>
                                          </div>
                                          <div className="status-item status-head">
                                            <h5 className="created">
                                              {t.status_real_name}
                                            </h5>
                                            <div className="date">
                                              {moment(t.timestamp).format(
                                                "DD MMM yyyy, h:mm a"
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } else if (i == data.tracking.length - 1) {
                                      return (
                                        <>
                                          <div className="status-box">
                                            <div className="status-badge">
                                              <i className="ri-check-line checked"></i>
                                            </div>
                                          </div>
                                          <div className="status-box status-head">
                                            <h5 className="created">
                                              {t.status_real_name}
                                            </h5>
                                            {t.driver_code && (
                                              <p>
                                                Driver Code: {t.driver_code}
                                              </p>
                                            )}
                                            <div className="date">
                                              {moment(t.timestamp).format(
                                                "DD MMM yyyy, h:mm a"
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <div className="status-box">
                                            <div className="status-badge">
                                              <i className="ri-check-line checked"></i>
                                            </div>
                                          </div>
                                          <div className="status-box status-head">
                                            <h5 className="created">
                                              {t.status_real_name}
                                            </h5>
                                            <div className="date">
                                              {moment(t.timestamp).format(
                                                "DD MMM yyyy, h:mm a"
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={8}>
                            <div className="order-details-part order-status">
                              <div className="status text-start ">
                                <h2 className="details fontfamily">
                                  Order Details
                                </h2>
                              </div>
                              <div className=" half-a-border-on-left">
                                <Row>
                                  <Col lg={6}>
                                    <div className="status-head">
                                      <h5 className="created">AWB</h5>
                                      <div className="date order-detail-content">
                                        {data.awb_number}
                                      </div>
                                    </div>
                                    <div className="status-detail-item"></div>
                                    <div className="status-detail-item status-head">
                                      <h5 className="created">
                                        Shipment Status
                                      </h5>
                                      <div className="date order-detail-content">
                                        {data.tracking &&
                                          data.tracking[
                                            data.tracking.length - 1
                                          ].status_real_name}
                                      </div>
                                    </div>
                                    <div className="status-detail-item "></div>
                                    <div className="status-detail-item  status-head">
                                      <h5 className="created">Description</h5>
                                      <div className="date order-detail-content">
                                        {data.address &&
                                          data.address.description}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className=" status-head pt-5 pt-lg-0">
                                      <h5 className="created">Last Updated</h5>
                                      <div className="date order-detail-content">
                                        {data.tracking &&
                                          moment(
                                            data.tracking[
                                              data.tracking.length - 1
                                            ].timestamp
                                          ).format("DD MMM yyyy, h:mm a")}
                                      </div>
                                    </div>
                                    <div className="status-detail-item "></div>
                                    <div className="status-detail-item  status-head">
                                      <h5 className="created">Service Code</h5>
                                      <div className="date order-detail-content">
                                        {data.address &&
                                          data.address.service_code}
                                      </div>
                                    </div>
                                  </Col>
                                  <div className="status-detail-item"></div>
                                  <div className=" status-head">
                                    <hr className="break"></hr>
                                  </div>
                                  <div className="status-detail-item"></div>
                                  <div className=" status-head">
                                    <h2 className=" details">
                                      Delivery Address
                                    </h2>
                                  </div>
                                  <div className="status-detail-item"></div>
                                  <Col md={6} className=" status-head">
                                    <h5 className="created">
                                      {data.address &&
                                        data.address.receiver_name}
                                    </h5>
                                    <div className="date order-detail-content">
                                      {data.address &&
                                        data.address.receiver_address}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    ))}
                  </Tabs>
                )}

                {/* {trackData ? (
                  trackData.data ? (
                    <Row>
                      <Col lg={6} md={4}>
                        <div className="order-details-part order-main order-status">
                          <div className="text-start">
                            <h2 className="details fontfamily">Order Status</h2>
                          </div>
                          <div className=" half-a-border-on-left">
                            {trackData.data.tracking &&
                              trackData.data.tracking.map((t, i) => {
                                if (i === 0) {
                                  return (
                                    <>
                                      <div className="status-item">
                                        <div className="status-badge status-created">
                                          <i className="ri-check-line checked"></i>
                                        </div>
                                      </div>
                                      <div className="status-item status-head">
                                        <h5 className="created">
                                          {t.status_real_name}
                                        </h5>
                                        <div className="date">
                                          {moment(t.timestamp).format(
                                            "DD MMM yyyy, h:mm a"
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                } else if (
                                  i ==
                                  trackData.data.tracking.length - 1
                                ) {
                                  return (
                                    <>
                                      <div className="status-box">
                                        <div className="status-badge">
                                          <i className="ri-check-line checked"></i>
                                        </div>
                                      </div>
                                      <div className="status-box status-head">
                                        <h5 className="created">
                                          {t.status_real_name}
                                        </h5>
                                        <div className="date">
                                          {moment(t.timestamp).format(
                                            "DD MMM yyyy, h:mm a"
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div className="status-box">
                                        <div className="status-badge">
                                          <i className="ri-check-line checked"></i>
                                        </div>
                                      </div>
                                      <div className="status-box status-head">
                                        <h5 className="created">
                                          {t.status_real_name}
                                        </h5>
                                        <div className="date">
                                          {moment(t.timestamp).format(
                                            "DD MMM yyyy, h:mm a"
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={8}>
                        <div className="order-details-part order-status">
                          <div className="status text-start ">
                            <h2 className="details fontfamily">
                              Order Details
                            </h2>
                          </div>
                          <div className=" half-a-border-on-left">
                            <Row>
                              <Col lg={6}>
                                <div className="status-head">
                                  <h5 className="created">AWB</h5>
                                  <div className="date order-detail-content">
                                    {trackID}
                                  </div>
                                </div>
                                <div className="status-detail-item"></div>
                                <div className="status-detail-item status-head">
                                  <h5 className="created">Shipment Status</h5>
                                  <div className="date order-detail-content">
                                    {trackData &&
                                      trackData.data &&
                                      trackData.data.tracking &&
                                      trackData.data.tracking[
                                        trackData.data.tracking.length - 1
                                      ].status_real_name}
                                  </div>
                                </div>
                                <div className="status-detail-item "></div>
                                <div className="status-detail-item  status-head">
                                  <h5 className="created">Description</h5>
                                  <div className="date order-detail-content">
                                    {trackData &&
                                      trackData.data &&
                                      trackData.data.address &&
                                      trackData.data.address.description}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className=" status-head pt-5 pt-lg-0">
                                  <h5 className="created">Last Updated</h5>
                                  <div className="date order-detail-content">
                                    {trackData &&
                                      trackData.data &&
                                      trackData.data.tracking &&
                                      moment(
                                        trackData.data.tracking[
                                          trackData.data.tracking.length - 1
                                        ].timestamp
                                      ).format("DD MMM yyyy, h:mm a")}
                                  </div>
                                </div>
                                <div className="status-detail-item "></div>
                                <div className="status-detail-item  status-head">
                                  <h5 className="created">Service Code</h5>
                                  <div className="date order-detail-content">
                                    {trackData &&
                                      trackData.data &&
                                      trackData.data.address &&
                                      trackData.data.address.service_code}
                                  </div>
                                </div>
                              </Col>
                              <div className="status-detail-item"></div>
                              <div className=" status-head">
                                <hr className="break"></hr>
                              </div>
                              <div className="status-detail-item"></div>
                              <div className=" status-head">
                                <h2 className=" details">Delivery Address</h2>
                              </div>
                              <div className="status-detail-item"></div>
                              <Col md={6} className=" status-head">
                                <h5 className="created">
                                  {trackData &&
                                    trackData.data &&
                                    trackData.data.address &&
                                    trackData.data.address.receiver_name}
                                </h5>
                                <div className="date order-detail-content">
                                  {trackData &&
                                    trackData.data &&
                                    trackData.data.address &&
                                    trackData.data.address.receiver_address}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="py-4">
                      <img
                        alt="track-image"
                        src={trackingNoData}
                        height={250}
                        className=""
                      ></img>
                      <div className="text-center">
                        <div className="status-detail-item"></div>

                        <h2 className="details fontfamily">No Results </h2>
                        <div className="status-detail-item"></div>

                        <div className="created">
                          Please enter a valid AWB ID
                        </div>
                        <div className="status-detail-item"></div>
                        <div className="status-detail-item"></div>
                      </div>
                    </Row>
                  )
                ) : (
                  <Row className="py-4">
                    <img
                      alt="track-image"
                      src={trackingImage}
                      height={350}
                    ></img>
                    <div className="text-center px-lg-0 px-5">
                      <div className="status-detail-item"></div>

                      <h2 className="details fontfamily pt-lg-4">
                        Track Your Orders Easily
                      </h2>

                      <div className="created">
                        Just enter your AWB tracking number or Order ID & it’s
                        done.
                      </div>
                      <div className="status-detail-item"></div>
                      <div className="status-detail-item"></div>
                    </div>
                  </Row>
                )} */}
              </div>
            </Row>
          </Container>
        </div>
        {/* Footer section */}
        <Footer />
      </section>
    </>
  );
  // }
}
export default Track;
