import React, { Component } from "react";
import { Container, Row, Col, Card, CardGroup } from "react-bootstrap";
import logisticsmain from "../Assets/images/logistics-main1.png";
import coldchain from "../Assets/images/cold-chain.svg";
import distribution from "../Assets/images/distribution-center.svg";
import accountmanagement from "../Assets/images/account-management.svg";
import comprehensive from "../Assets/images/comprehensive.png";
import trackicon from "../Assets/images/logistic-tracking.svg";
import customsicon from "../Assets/images/logistic-customs.svg";
import dutytax from "../Assets/images/duty-tax.svg";
import "../Css/Logistics.css";
import insurance from "../Assets/images/insurance.svg";
import fourpl from "../Assets/images/fourpl.svg";
import customersupport from "../Assets/images/customer-support1.svg";
import valueadded from "../Assets/images/value-added-icon.svg";
import Footer from "./Footer";

class Logistics extends Component {
    render() {
        return (
            <div>
                <div className="logistics-section ">
                    <div className="logistic-sect bg-white d-lg-block  ">
                        <Container>
                            <Row className="align-items-center  ">
                                <Col lg={7} className="bg-white safe-head " >
                                    <div className="safe-content mt-lg-0 mt-4">
                                        <h2 className="our-company safe-reliable-content ">Bridging Gaps<span className="essence"> Delivering Success</span></h2>

                                    </div>
                                </Col>
                                <Col lg={5} className=" order-lg-last p-0 pe-lg-0">
                                    <div className="safe-reliable  d-lg-none  d-block mt-3" >

                                        <img src={logisticsmain} className="img-fluid " />


                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    {/* Efficient Warehousing Solutions */}

                    <div className="efficient-warehouse industry-solution  bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={10} className="text-center mb-5 mb-lg-0" >
                                    <div className="reliable-head efficient-head pt-lg-0 pt-4 pb-3">Efficient Warehousing Solutions</div>
                                    <div className="efficient-sub">Our state-of-the-art warehousing facilities are designed to meet the diverse needs of our clients, Ensuring the secure storage and efficient handling of your goods.</div>
                                </Col>
                            </Row>
                            <Row className="text-center mt-lg-5 g-0 " >

                                <Col lg={4} md={6} className="ship-rate pe-lg-2">
                                    <div className="bg-white px-3 py-5">
                                        <img src={coldchain} />

                                        <div className="shipping-rates pt-3">Cold Chain</div>
                                        <div className="shipping-rates-content account-management ">
                                            Temperature-sensitive packaging, transportation and warehouse for a supply chain with controlled conditions.
                                        </div>
                                    </div>

                                </Col>
                                <Col lg={4} md={6} className="ship-rate pe-lg-2">
                                    <div className="bg-white px-3 py-5">
                                        <img src={distribution} />

                                        <div className="shipping-rates pt-3">Distribution Center Management</div>
                                        <div className="shipping-rates-content account-management ">
                                            Stock accuracy, cycle count, picking, and order preparation for global distribution by various transportation modes.
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} className="ship-rate">
                                    <div className="bg-white px-3 py-5">
                                        <img src={accountmanagement} />

                                        <div className="shipping-rates pt-3">Account Management</div>
                                        <div className="shipping-rates-content account-management ">
                                            A centralized and dedicated global account manager and team for your logistics operations.
                                        </div>
                                    </div>

                                </Col>

                            </Row>
                            <Row className="text-center mt-lg-4 g-0" >
                                <Col lg={4} md={6} className="ship-rate pe-lg-2 ">
                                    <div className="bg-white px-3 py-5  ">
                                        <img src={customersupport} />

                                        <div className="shipping-rates pt-3">Call Center Management</div>
                                        <div className="shipping-rates-content ">
                                            Returns, customer complaints, data entry and email and social media <br></br>support.
                                        </div>
                                    </div>

                                </Col>
                                <Col lg={4} md={6} className="ship-rate pe-lg-2">
                                    <div className="bg-white px-3 py-5">
                                        <img src={fourpl} />

                                        <div className="shipping-rates pt-3">4PL</div>
                                        <div className="shipping-rates-content ">
                                            Short and long-term warehouse space with innovative technology and integrated customer systems.
                                        </div>
                                    </div>

                                </Col>
                                <Col lg={4} className="ship-rate">
                                    <div className="bg-white px-3 py-5">
                                        <img src={valueadded} />

                                        <div className="shipping-rates pt-3">Value Added Services</div>
                                        <div className="shipping-rates-content pb-4 ">
                                            Repacking , labelling, price tagging and <br></br> kitting.
                                        </div>
                                    </div>

                                </Col>
                            </Row>




                        </Container>
                    </div>


                    {/* Customer Security & Insurance */}
                    <div className="customer-security-section pb-5 bg-lg-white">
                        <div className=" ">


                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg={9} className="text-center mb-4 mb-lg-0" >
                                        <div className="reliable-head efficient-head pt-lg-5 pt-4 pb-3">Customer Security & Insurance</div>
                                        <div className="efficient-sub">Ensuring the safety and security of your shipments is our top priority. We understand the value and importance of your cargo, Which is why we have implemented robust security measures throughout our logistics operations.</div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center ps-3 ps-lg-0 ">
                                 
                                        <Col lg={5} className="comprehensive-img-background my-lg-5">
                                            {/* <div className="d-lg-none d-block">
                                                <img src={comprehensive} className="img-fluid d-none d-lg-block" />
                                            </div> */}
                                        </Col>
                                        <Col lg={7} className="ps-lg-5 ps-0 pt-0 " >
                                            <div className="reliable-head rapid-solution pb-3 pb-lg-0 ">Comprehensive Protection</div>
                                            <img src={comprehensive} className="img-fluid d-lg-none d-block" />


                                            <Row>
                                                <Col lg={6} md={6}>
                                                    <div className="domestic-express">
                                                        <div className=" py-lg-5 pt-4 pt-lg-0">
                                                            <div className="domestic-shade insurance-shade"></div>
                                                            <img src={insurance} className="domestic-icon-img" />
                                                            <h2 className="domestic-head mt-3">Insurance</h2>

                                                            <div className="">Optional freight insurance that covers damage and loss for multiple locations.</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6}>
                                                    <div className="domestic-express">
                                                        <div className=" py-lg-5 pt-4 pt-lg-0">
                                                            <div className="domestic-shade insurance-shade"></div>
                                                            <img src={trackicon} className="domestic-icon-img" />
                                                            <h2 className="domestic-head  mt-3">Tracking</h2>

                                                            <div className="">AI and data process tracking from origin to destination in real-time for extra security.</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6} md={6}>
                                                    <div className="domestic-express">
                                                        <div className="pt-lg-0 pt-4">
                                                            <div className="domestic-shade insurance-shade"></div>
                                                            <img src={customsicon} className="domestic-icon-img" />
                                                            <h2 className="domestic-head mt-3">Customs</h2>

                                                            <div className="">Swift same-day, next-day, and scheduled deliveries available.</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6}>
                                                    <div className="domestic-express">
                                                        <div className="pt-lg-0 pt-4">
                                                            <div className="domestic-shade insurance-shade"></div>
                                                            <img src={dutytax} className="domestic-icon-img" />
                                                            <h2 className="domestic-head  mt-3">Duty & Tax</h2>

                                                            <div className="">Instant duty & tax calculations with HS Code classifications.</div>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>


                                        </Col>

                                  

                                </Row>

                            </Container>
                        </div>
                    </div>

                    <Footer />

                </div>
            </div>
        )
    }
}
export default Logistics;