import 'bootstrap/dist/css/bootstrap.min.css';  
import FlipNav from './Components/FlipNav';
import { BrowserRouter } from 'react-router-dom';
function App() {  
  return (  
    <BrowserRouter>  
      <FlipNav/>
      </BrowserRouter>
  )
}
export default App;