import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Nav, NavDropdown, Button, Col, Row } from 'react-bootstrap';
import logo from "../Assets/images/logo-footer.png";
import "../Css/FlipNav.css";
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom/cjs/react-router-dom.min';

import { BrowserRouter as Router, Switch, Route, NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Express from './Express';
import Home from './Home';
import Logistics from './Logistics';
import Services from './Services';
import Aboutus from './Aboutus';
import Contact from './Contact';
import Track from "./Track";
import Login from "./Login";
import Signup from './Signup';
function FlipNav() {
    const history = useHistory();
    const handleTrack = () => {
        console.log("click");
        history.push('/track');
    }
    return (
        <>
            <Router>
                <Navbar collapseOnSelect expand="lg" variant="dark" className='nav-bar'>
                    <Container   >

                       
                                <Navbar.Brand className='logo-main' href="/" >
                                    <img src={logo} width="95px" height="56px" />
                                  
                                </Navbar.Brand>
                                <NavLink to="/track" className='d-block d-lg-none btn  trackbutton'>TRACK</NavLink>
                           
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav"  >
                                    <Nav className=" me-auto ">
                                        <NavLink exact to="/" className="home px-4" >HOME</NavLink>
                                        <NavLink to="/express" className="home px-4" >EXPRESS</NavLink>
                                        <NavLink to="/logistics" className="home px-4" >LOGISTICS</NavLink>
                                        <NavLink to="/services" className="home px-4" >SPECIAL SERVICES</NavLink>
                                        <NavLink to="/aboutus" className="home px-4" >ABOUT US</NavLink>
                                        <NavLink to="/contact" className="home px-4" >CONTACT</NavLink>


                                    </Nav>
                                    <Nav className='track-login  '>
                                   
                                   <NavLink to="/track" className='d-none d-lg-block btn  trackbutton'>TRACK </NavLink>
                                 
                                   <a href="https://ship.flipxp.com/"><button className='btn  login'>LOG IN</button></a>
                                  
                             
                              

                           </Nav>
                                </Navbar.Collapse>
                    
                          

                    </Container>
                </Navbar>


                <Switch>
   
                    <Route exact path="/" component={Home}></Route>
                    <Route path="/express" component={Express}></Route>
                    <Route path="/logistics" component={Logistics}></Route>
                    <Route path="/services" component={Services}></Route>
                    <Route path="/aboutus" component={Aboutus}></Route>
                    <Route path="/contact" component={Contact}></Route>
                    <Route path="/track" component={Track}></Route>
                    <Route path="/signup" component={Signup}></Route>
                    <Route path="/login" component={Login}></Route>
                </Switch>
              
      {/* <Switch>
        <Route path="/express" element={<Express />}>
          <Route index element={<Home />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/services" element={<Services />} />
          
        </Route>
      </Switch> */}
    

            </Router>
        </>
    )
}
export default FlipNav;