import React, { Component, useState, useRef } from "react";
import "../Css/Home.css";
import video from "../Assets/video/homevideo.mp4";
import freight from "../Assets/images/freight.png";
import express from "../Assets/images/express.png";
import ecommerce from "../Assets/images/e-commerce.png";
import { useHistory } from "react-router-dom";
import { Container, Row, FormControl, Form, Col } from "react-bootstrap";
import fast from "../Assets/images/fast.svg";
import fxpexpress from "../Assets/images/fxp-express2.png";
import freightlogi from "../Assets/images/freight-logistics2.png";
import Logistics from "../Assets/images/logistics.png";
import logistic from "../Assets/images/logistic.png";
import competitive from "../Assets/images/competitive.svg";
import secure from "../Assets/images/safe and secure.svg";
import world from "../Assets/images/worldwide.svg";
import support from "../Assets/images/support.svg";
import companyimg from "../Assets/images/company-essance.png";
import dabba from "../Assets/images/dabba.png";
import imglogic from "../Assets/images/imglogistic.png";
import imgecommerce from "../Assets/images/ecommerce.png";
import expressimg from "../Assets/images/expressimg.png";
import freightimg from "../Assets/images/freightimg.png";
import shippingrate from "../Assets/images/shipping-rates1.svg";
import customersupport from "../Assets/images/customer-support1.svg";
import frequentlyasked from "../Assets/images/frequent1.svg";
import Footer from "./Footer";
import Goals from "./Goals";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

function Home() {
  // render() {
  const history = useHistory();
  const trackRef = useRef();
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    let str = e.target.value.split(/[ ,]+/).join(",");
    setInput(str);
  };

  return (
    <div>
      <div
        className="flip "
        style={{ position: "relative", overflow: "hidden" }}
      >
        {/* <video loop autoPlay muted className="video " width="100%">
                    <source src={video} type="video/mp4" />
                </video> */}

        <video
          loop
          autoPlay
          muted
          className="video "
          width="100%"
          sx={{ top: 0, left: 0 }}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>

      <section className="home-main">
        {/* Track id */}
        {/* <div style={{ justifyContent: 'center', backgroundColor: '#002726', padding: '20px' }}>

                    <Container>

                      



                    </Container>


                    <div className={classes.container}>
                        <Container>
                            <div className={classes.box}>


                                <Grid container
                                   paddingTop="50px"
                                  
                                    
                                >
                                    <Grid item xs={12}>
                                    <Grid container justifyContent="center"  spacing={0} >
                                        <TextField style={{ backgroundColor: "white" }} sx={{width:"400px", height:"90px", padding:"20px", border:"none"}}/>
                                        <Button variant="contained" href="#contained-buttons" style={{ backgroundColor: "#9ACE21" }}>    Track
                                        </Button>
                                    </Grid>
                                    </Grid>

                                </Grid>

                            </div>

                        </Container>
                    </div>

                </div> */}

        <div className="track-id">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg={9} md={12}>
                <div className="search-form">
                  <Form className="d-flex track-form pt-2">
                    <FormControl
                      type="search"
                      placeholder="Enter your track ID"
                      className="me-2"
                      aria-label="Track"
                      ref={trackRef}
                      value={input}
                      onChange={handleInputChange}
                    />
                    {/* <button onClick={() => abc('/track')} variant="outline-success" className="btn track-search">Track</button> */}
                    <button
                      variant="outline-success"
                      className="btn track-search"
                      onClick={() => {
                        // console.log(trackRef.current.value);
                        history.push("/track", {
                          trackKey: input,
                        });
                      }}
                    >
                      TRACK
                    </button>
                  </Form>
                </div>
              </Col>
              <Col md={12}>
                <div className="text-center">
                  <p className="shipping">We ship everything and everywhere</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* E commerce Logistics Express Freight */}

        <Container fluid>
          <Row className="gx-0">
            <Col lg={3} sm={6} xs={6} className="p-0">
              <div className="e-commerce-main">
                <div className="e-commerce ">
                  <img className="img-fluid ecommerce-image " src={ecommerce} />
                  <div className="e-commerce-shadow">
                    <h5 className="ecommerce-text ">E commerce</h5>
                  </div>
                </div>
                <div className="logistics-shadow ">
                  <img src={imglogic} className="px-lg-5 px-4 imglogic"></img>
                  <br></br>
                  <img
                    src={imgecommerce}
                    className="px-lg-5 px-4 logistic-img"
                  />

                  <div className="logistic-main px-lg-5 px-4 ">
                    <h5 className="logistic-head ">E commerce</h5>
                    <p className="logistic-text">
                      Get the best tailor made rates & service for your
                      E-Commaserce business to help it grow further and target a
                      wide array of customers.
                    </p>
                    <button
                      className="btn track-search read-more read mt-lg-4"
                      onClick={() => history.push("/services")}
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={6} xs={6} className="p-0">
              <div className="logistics">
                <div className="e-commerce ">
                  <img className="img-fluid ecommerce-image " src={logistic} />
                  <div className="e-commerce-shadow">
                    <h5 className="ecommerce-text  ">Logistics</h5>
                  </div>
                </div>
                <div className="logistics-shadow ">
                  <img src={imglogic} className="px-lg-5 px-4 imglogic"></img>
                  <br></br>
                  <img src={Logistics} className="px-lg-5 px-4 logistic-img" />

                  <div className="logistic-main px-lg-5 px-4 ">
                    <h5 className="logistic-head ">Logistics</h5>
                    <p className="logistic-text">
                      FlipXpress provides custom-made logistics solutions for
                      all your business needs which include procurement, storage
                      and delivery.
                    </p>
                    <button
                      className="btn track-search read-more read mt-lg-4"
                      onClick={() => history.push("/logistics")}
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={6} xs={6} className="p-0">
              <div className="express">
                <div className="e-commerce">
                  <img className="img-fluid ecommerce-image" src={express} />
                  <div className="e-commerce-shadow">
                    <h5 className="ecommerce-text ">Express</h5>
                  </div>
                </div>
                <div className="logistics-shadow ">
                  <img src={imglogic} className="px-lg-5 px-4 imglogic"></img>
                  <br></br>
                  <img src={expressimg} className="px-lg-5 px-4 logistic-img" />

                  <div className="logistic-main px-lg-5 px-4 ">
                    <h5 className="logistic-head ">Express</h5>
                    <p className="logistic-text">
                      As the name suggests, with FlipXpress' express solutions
                      backed with decades of experience & reliable resources,
                      your shipment will get to the final destination fast.
                    </p>
                    <button
                      className="btn track-search read-more read mt-lg-4"
                      onClick={() => history.push("/express")}
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={6} xs={6} className="p-0">
              <div className="freight">
                <div className="e-commerce">
                  <img className="img-fluid ecommerce-image" src={freight} />
                  <div className="e-commerce-shadow">
                    <h5 className="ecommerce-text ">Freight</h5>
                  </div>
                </div>
                <div className="logistics-shadow ">
                  <img src={imglogic} className="px-lg-5 px-4 imglogic"></img>
                  <br></br>
                  <img src={freightimg} className="px-lg-5 px-4 logistic-img" />

                  <div className="logistic-main px-lg-5 px-4 ">
                    <h5 className="logistic-head ">Freight</h5>
                    <p className="logistic-text">
                      The logistics industry relies on fast turnaround,
                      innovative solution, efficient operations and on time
                      delivery and this requires massive investment in human and
                      physical resources.
                    </p>
                    <button
                      className="btn track-search read-more read mt-lg-4"
                      onClick={() => history.push("/services")}
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="fast-icon">
          <Container>
            <Row className="faster py-5">
              <Col lg={2} md={4} xs={4} className="text-center">
                <div className=" fast">
                  <img className="img-fluid" src={fast}></img>
                  <div className="py-3">Fast</div>
                </div>
              </Col>
              <Col lg={2} md={4} xs={4} className="text-center">
                <div className="fast">
                  <img className="img-fluid" src={competitive}></img>
                  <div className="py-3">Competitive</div>
                </div>
              </Col>
              <Col lg={2} md={4} xs={4} className="text-center">
                <div className="fast">
                  <img className="img-fluid" src={secure}></img>
                  <div className="py-3 ">Safe and Secure</div>
                </div>
              </Col>
              <Col lg={2} md={4} xs={6} className="text-center">
                <div className="fast">
                  <img className="img-fluid" src={world}></img>
                  <div className="py-3 ">World wide</div>
                </div>
              </Col>
              <Col lg={2} md={4} xs={6} className="text-center">
                <div className="fast">
                  <img className="img-fluid" src={support}></img>
                  <div className="py-3">24/7 Support</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Companies essence */}

        <div className="bg-light">
          <Container className="py-1">
            <Row className="align-items-center company-main">
              <Col lg={6}>
                <div className="company-essence-background">
                  {/* <img className="img-fluid d-lg-block d-none" src={companyimg} /> */}
                </div>
              </Col>
              <Col lg={6} className="ps-lg-5">
                <div className="company-essance">
                  <h3 className="companies  pb-4 pt-lg-5 pb-lg-0">
                    Companies <span className="companies essance">essence</span>
                  </h3>
                  <div>
                    <img src={companyimg} className="img-fluid d-lg-none" />
                  </div>
                  <p className="pt-4 pt-lg-0">
                    Flip Xpress has an extended reach all over UAE and
                    international reach to more than 100 destinations. We
                    provide the best and most cost-effective shipping services
                    available. Flip Xpress has its specialized logistics
                    services, strives to meet the needs of individuals and
                    businesses for domestic and international parcel delivery.
                    Flip Xpress has established a vast network that connects
                    almost every city and town through the United Arab Emirates.
                  </p>
                  <button
                    className="btn track-search read-more mt-4"
                    onClick={() => history.push("/aboutus")}
                  >
                    READ MORE
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Fxp Express  Freight & Logistics */}

        <Container fluid>
          <Row className="g-0">
            <Col lg={6} className="p-0">
              <div className="fxp-express fxp-express-dot">
                <Row className="align-items-center">
                  <Col lg={6} className="order-last order-lg-first">
                    <img className="img-fluid fxpexpress " src={fxpexpress} />
                  </Col>
                  <Col lg={6}>
                    <div className=" fxp-express-view px-lg-3">
                      <h2 className="fxp">
                        Fxp <br /> Express
                      </h2>
                      <div className="text-white">
                        Flip Xpress is a 1 stop shop for all your courier,
                        cargo, warehousing, supply chain and logistics needs
                      </div>
                      <button
                        className=" btn track-search read-more read mt-4"
                        onClick={() => history.push("/express")}
                      >
                        READ MORE
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="p-0 freight-log-side">
              <div className="freight-logistics freight-logistic-dot">
                <Row className="align-items-center">
                  <Col lg={6} xs="auto">
                    <div className="freight-logistic-content">
                      <h2 className="fxp">Freight & Logistics</h2>
                      <div className="text-white">
                        Logistics is crucial component of any organisation so at
                        FXP we provide customized logistics solutions for all
                        your company needs whether its air freight, sea freight
                        or land transport we provide you all the solutions your
                        business needs
                      </div>
                      <button
                        className="btn  track-search read-more read mt-4"
                        onClick={() => history.push("/services")}
                      >
                        READ MORE
                      </button>
                    </div>
                  </Col>
                  <Col lg={6} xs="auto">
                    <img className="fxpexpress img-fluid" src={freightlogi} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Launching soon Dabba. */}

        <div className="bg-white">
          <Container>
            <Row className="align-items-center pt-5">
              <Col md={5}>
                <img className="img-fluid" src={dabba}></img>
              </Col>
              <Col md={7} className="order-lg-last order-md-last order-first">
                <div className="Launchin-view">
                  <h2 className=" launching ">Launching soon</h2>
                  <div className=" essance dabba pb-lg-5 pb-3">Dabba.</div>
                  <p className="launching-content py-3">
                    Have your shopping delivered from the US, UK and many other
                    Dabba locations.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Customer Service */}

        <div className="bg-light customer-sect  ">
          <Container>
            <Row className="text-center customer">
              <div className="customer ">
                <h2 className="customer-services pb-3">Customer Service </h2>
              </div>

              <Col lg={4} className="ship-rate">
                <div className="bg-white px-4 py-4">
                  <img src={shippingrate} />

                  <div className="shipping-rates pt-3">Shipping rates</div>
                  <div className="shipping-rates-content ">
                    Check our competitive rates and send shipments to anywhere
                    in the world
                  </div>
                </div>
              </Col>
              <Col lg={4} className="ship-rate">
                <div className="bg-white px-5 py-4">
                  <img src={customersupport} />

                  <div className="shipping-rates pt-3">Customer Support</div>
                  <div className="shipping-rates-content ">
                    Inquire about your deliveries, return shipments, or report a
                    problem
                  </div>
                </div>
              </Col>
              <Col lg={4} className="">
                <div className="bg-white px-4 py-4">
                  <img src={frequentlyasked} />

                  <div className="shipping-rates pt-3">
                    Frequently Asked Questions
                  </div>
                  <div className="shipping-rates-content ">
                    Common questions and answers about Flip Press products and
                    services
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Our Goals */}

        <div className="bg-white ">
          <Container className="ourgoals">
            <Row className="text-center ">
              <div className="our-goals  ">
                <h2 className="customer-services goalsnum">
                  Our goals in numbers{" "}
                </h2>

                <div className="">
                  Flip Xpress is the worldwide coordination supplier - we uphold
                  industry<br></br> and exchange the worldwide trade through
                  transport.
                </div>
              </div>
              <Goals />
            </Row>
          </Container>
        </div>

        {/* Footer section */}

        <Footer />
      </section>
    </div>
  );
  // }
}
export default Home;
