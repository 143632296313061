import React, { Component } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import contact from "../Assets/images/contact-img1.png";
import newaccount from "../Assets/images/create-new.svg";
import location from "../Assets/images/location.svg";
import contactus from "../Assets/images/contacticon.svg";
import getin from "../Assets/images/getin-touch.png";
import workinghour from "../Assets/images/working-hours.svg";
import shippingrate from "../Assets/images/shipping-rates1.svg";
import customersupport from "../Assets/images/customer-support1.svg";
import frequentlyasked from "../Assets/images/frequent1.svg";
import sendshipment from "../Assets/images/send-shipment.svg";
import trackshipment from "../Assets/images/track-shipment.svg";

import "../Css/Contact.css";
import Contactform from "./Contactform";
import Footer from "./Footer";

class Contact extends Component {
  render() {
    return (
      <div>
        <div className="contact-section  ">
          <div className="contact-sect d-lg-block">
            <Container>
              <Row className="align-items-center  ">
                <Col lg={7} className="bg-white safe-head">
                  <div className="safe-content mt-lg-0 mt-4">
                    <h2 className="our-company safe-reliable-content">
                      We Will Be Glad
                      <br />
                      <span className="essence"> To Help You.</span>
                    </h2>
                  </div>
                </Col>
                <Col lg={5} className=" order-lg-last   pe-lg-0 p-0">
                  <div className="safe-reliable d-lg-none  d-block mt-3">
                    <img src={contact} className="img-fluid  " />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Contact location Working */}
          <div className="contact-location-section  bg-light">
            <Container>
              <Row className="  contact-location ">
                <Col lg={4} className="px-3">
                  <Card className="mb-3 mb-lg-0 contact-card">
                    <Row className=" g-0 align-items-baseline ">
                      <Col md="auto" xs="auto" className=" pe-0">
                        <div className="bg-white  ps-3 ps-lg-4 pt-4 pt-lg-0 py-lg-5">
                          <div className="domestic-shade location-shade"></div>
                          <img src={location} className=" " />
                        </div>
                      </Col>
                      <Col>
                        <Card.Body className="ps-lg-0 ">
                          <Card.Title className="shipping-rates contact-loc pt-0 ">
                            LOCATION
                          </Card.Title>
                          <Card.Text className="shipping-rates-content  pt-0">
                            {" "}
                            Warehouse #7, 7A Street, Behind Promar LLC, Umm
                            Ramool, Dubai U.A.E.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg={4} className="px-3 ">
                  <Card className="mb-3 mb-lg-0 contact-card">
                    <Row className=" g-0 align-items-baseline ">
                      <Col md="auto" xs="auto" className=" pe-0">
                        <div className="bg-white ps-3 ps-lg-4 pt-4 pt-lg-0 py-lg-5">
                          <div className="domestic-shade location-shade"></div>
                          <img src={contactus} />
                        </div>
                      </Col>
                      <Col>
                        <Card.Body className="ps-lg-0">
                          <Card.Title className="shipping-rates contact-loc pt-0">
                            CONTACT US
                          </Card.Title>
                          <Card.Text className="shipping-rates-content pb-4 pt-0">
                            <div className="p-0">
                              Phone : <span className=""> +971 4 324 9993</span>
                            </div>
                            <div className="p-0">
                              Email :{" "}
                              <span className="pe-0">
                                {" "}
                                atyourservice@flipxp.com
                              </span>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg={4} className="px-3 ">
                  <Card className=" mb-lg-0 contact-card">
                    <Row className=" g-0 align-items-baseline ">
                      <Col md="auto" xs="auto" className="pb-5 pe-0">
                        <div className="bg-white ps-3 ps-lg-4 pt-4 pt-lg-0 py-lg-5">
                          <div className="domestic-shade location-shade"></div>
                          <img src={workinghour} />
                        </div>
                      </Col>
                      <Col>
                        <Card.Body className="ps-lg-0">
                          <Card.Title className="shipping-rates contact-loc pt-0">
                            WORKING HOURS
                          </Card.Title>
                          <Card.Text className="shipping-rates-content pb-4 pt-0">
                            Everyday
                            <br />
                            <div className=""> 08.00 AM-09:00 AM</div>
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Get in touch */}

          <div className="get-in-touch ">
            <Container>
              <Row className="align-items-center company-main ">
                <Col lg={6} className="ps-0">
                  <div className="reliable-head mb-4 mb-lg-0">Get in touch</div>
                  {/* <img src={getin} className="img-fluid d-lg-none d-block" /> */}
                  <iframe
                    className="d-lg-none d-block"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28873.67198422328!2d55.360753!3d25.2298847!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d9d32f82057%3A0x3760939acd8e56ea!2s69J6%2B24F%20-%20Umm%20Ramool%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1698739118951!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    // style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  {/* <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                     
                      <Col sm="10">
                        <Form.Control type="text" placeholder="Your Name" />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" type="email" placeholder="Your Mail">
                      
                      <Col sm="10">
                        <Form.Control type="address" placeholder="message" />
                      </Col>
                    </Form.Group>
                  </Form> */}
                  <div className="mt-4 mt-lg-0">
                    <Contactform />
                  </div>
                </Col>
                <Col
                  lg={6}
                  className="order-first order-lg-last order-md-first "
                >
                  {/* <img src={getin} className="img-fluid d-lg-block d-none" /> */}
                  <iframe
                    className="d-lg-block d-none"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28873.67198422328!2d55.360753!3d25.2298847!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d9d32f82057%3A0x3760939acd8e56ea!2s69J6%2B24F%20-%20Umm%20Ramool%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1698739118951!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    // style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Customer Service */}

          <div className="bg-light">
            <Container className="contact-customer ">
              <Row className="text-center   ">
                <div className="customer  ">
                  <h2 className="customer-services efficient-head pb-lg-5 pb-4">
                    Let’s get you the right help{" "}
                  </h2>
                </div>
              </Row>

              <Row className="text-center  mx-lg-0 g-0 pb-lg-4  ">
                <Col lg={4} className="ship-rate pe-3 pe-lg-2">
                  <div className="bg-white px-3 py-5 mx-3 mx-lg-0">
                    <img src={shippingrate} />

                    <div className="shipping-rates pt-3">Shipping rates</div>
                    <div className="shipping-rates-content ">
                      Check our competitive rates and send shipments to anywhere
                      in the world
                    </div>
                  </div>
                </Col>

                <Col lg={4} className="ship-rate pe-3 pe-lg-2">
                  <div className="bg-white px-3 py-5 mx-3 mx-lg-0">
                    <img src={customersupport} />

                    <div className="shipping-rates pt-3">Customer Support</div>
                    <div className="shipping-rates-content ">
                      Inquire about your deliveries, return shipments, or report
                      a problem
                    </div>
                  </div>
                </Col>
                <Col lg={4} className="ship-rate pe-3 pe-lg-2">
                  <div className="bg-white px-3 py-5 mx-3 mx-lg-0">
                    <img src={frequentlyasked} />

                    <div className="shipping-rates pt-3">
                      Frequently Asked Questions
                    </div>
                    <div className="shipping-rates-content ">
                      Common questions and answers about Flip Press products and
                      services
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="text-center pb-lg-4  mx-lg-0 g-0">
                <Col lg={4} className="ship-rate pe-3 pe-lg-2">
                  <div className="bg-white px-3 py-5 mx-3 mx-lg-0">
                    <img src={newaccount} />

                    <div className="shipping-rates pt-3">
                      Create New Account
                    </div>
                    <div className="shipping-rates-content ">
                      Create an account and send unlimited shipments with ease.
                    </div>
                  </div>
                </Col>
                <Col lg={4} className="ship-rate pe-3 pe-lg-2">
                  <div className="bg-white px-3 py-5 mx-3 mx-lg-0">
                    <img src={sendshipment} />

                    <div className="shipping-rates pt-3">Send Shipments</div>
                    <div className="shipping-rates-content ">
                      Check prices and send shipments in <br /> few clicks.
                    </div>
                  </div>
                </Col>
                <Col lg={4} className=" pe-3 pe-lg-2">
                  <div className="bg-white px-4 py-5 mx-3 mx-lg-0">
                    <img src={trackshipment} />

                    <div className="shipping-rates pt-3">Track Shipments</div>
                    <div className="shipping-rates-content ">
                      Get the latest update and location on your shipment.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
